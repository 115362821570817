angular.module("app")
    .controller("maintenanceController", ["$scope", "$rootScope", "$log", "$state", "$stateParams", "$timeout", "bookingService", function ($scope, $rootScope, $log, $state, $stateParams, $timeout, bookingService) {

        var vm = this;

        vm.message = $stateParams.message;

        var SIXTY_SECONDS = 60 * 1000;
        var maintenancePollTimer;

        function poll() {
            bookingService.listEnroute().then(function (bookings) {

                $state.go('bookings');

            }).catch(function (error) {
                $log.debug('error: ' + error.data);
            })['finally'](function () {
                setPollTimeout();
            });
        }


        function setPollTimeout() {
            cancelPollTimeout();
            maintenancePollTimer = $timeout(poll, SIXTY_SECONDS);
        }

        function cancelPollTimeout() {
            if (maintenancePollTimer) $timeout.cancel(maintenancePollTimer);
        }

        $rootScope.$on('$locationChangeSuccess', function () {
            if ($state.current.url !== "/maintenance") {
                cancelPollTimeout();
            }
        });


        poll();


    }])
;
